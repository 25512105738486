import React from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import 'prismjs/themes/prism.css';

const PostWrapper = styled.div`
  margin: 0 auto;
  font-size: 18px;
  font-family: 'Noto Sans Light', sans-serif;
  line-height: 27px;
  letter-spacing: ;
  padding-top: 100px;
  padding-bottom: 150px;

  @import url('https://cdnjs.cloudflare.com/ajax/libs/github-markdown-css/3.0.1/github-markdown.min.css');

  & pre {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  h1 {
    margin-bottom: 70px;
  }

  h1 ~ h1 {
    margin-top: 70px;
  }

  h2 {
    margin-top: 70px !important;
    font-size: 26px;
  }

  h3 {
    margin-top: 35px !important;
    font-size: 20px;
  }

  h4 {
    margin-top: 20px !important;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 700px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .big-img {
    width: 100%;
  }

  .hero {
    padding-top: 70px !important;
    margin-bottom: 70px;
  }

  ul,
  ol {
    margin-bottom: 0px;
  }

  li {
    margin-bottom: 5px;

    & p {
      margin-top: 0px;
      margin-bottom: 5px;
    }
  }

  hr {
    height: 0.07em !important;
  }

  p {
    text-align: justify;
    line-height: 1.5;
  }

  blockquote {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 700px;
    background-color: #fff !important;
    padding: 1.5rem 2rem 1.5rem 0.5rem;
    margin: 1rem auto 1.5rem auto;
    box-shadow: 0 1.5rem 1rem rgba(0, 0, 0, 0.05);
    border-left: 1.5rem solid #ed5565;
    border-right: 2px solid #da4453;
    font-family: 'lato', 'arial', 'sans-serif' !important;
    font-style: italic;
    font-weight: 300;
    text-align: left;
  }

  .highlight {
    background-color: rgba(255, 229, 100, 0.2);
  }

  .blog-post__content-image {
    width: 70%;
  }

  .formula-container {
    text-align: center;
  }

  .frac {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    letter-spacing: 0.001em;
    text-align: center;
    width: fit-content;
  }

  .frac > span {
    display: block;
    padding: 0.1em;
  }

  .frac span.bottom {
    border-top: thin solid black;
  }

  .frac span.symbol {
    display: none;
  }
`;

const Title = styled.h1`
  width: 700px;
  margin: 0 auto 70px auto;
`;

const Date = styled.p`
  width: 700px;
  margin: 0 auto;
`;

const MarkdownBody = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  & > * {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
`;

interface Post {
  title: string;
  date: string;
}

interface MarkdownRemark {
  html: string;
  frontmatter: Post;
}

interface Data {
  markdownRemark: MarkdownRemark;
}

function BlogPost({ data }: { data: Data }) {
  const post = data.markdownRemark;
  const { title, date } = post.frontmatter;

  return (
    <Layout>
      <PostWrapper>
        <Title>{title}</Title>
        <Date>{date}</Date>
        <MarkdownBody dangerouslySetInnerHTML={{ __html: post.html }} />
      </PostWrapper>
    </Layout>
  );
}

export default BlogPost;

export const query = graphql`
  query DetailsQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM Do YYYY")
      }
    }
  }
`;
